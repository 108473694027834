<template>
    <div class="publish-device">
        <Header />
        <el-form ref="form" :model="form" :rules="rules">
            <div class="container">
                <div class="w-1200">
                    <div class="publish-device-title">发布出租设备</div>
                    <div class="base-step">
                        <div class="step-item" :class="{ active: true }"><span class="num">1</span><span class="name">描述商品详情</span></div>
                        <i class="divider" :class="{ active: false }"></i>
                        <div class="step-item"><span class="num">2</span><span class="name">提交审核</span></div>
                        <i class="divider"></i>
                        <div class="step-item"><span class="num">3</span><span class="name">平台审核</span></div>
                    </div>
                    <section class="form">
                        <div class="form-title">基本信息</div>
                        <div class="form-main">
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span> 选择分类</div>
                                <el-form-item prop="inputs.type_id">
                                    <el-cascader ref="cascader" style="width: 350px" v-model="form.inputs.type_id" :options="checkList" :props="{ checkStrictly: true, value: 'type_id', label: 'type_name' }" clearable @change="handleChange"></el-cascader>
                                </el-form-item>
                                <!-- <div class="search">
                                    <el-form-item>
                                        <el-input v-model="keyWord" type="text" placeholder="搜索分类…" class="input"></el-input>
                                        <! -- <input type="text" placeholder="搜索分类…" class="input"> -- >
                                        <ul>
                                            <li v-for="item in searchResult" :key="item.type_id" @click="picktype(item.type_id)">{{ item.type_name }}</li>
                                        </ul>
                                    </el-form-item>
                                    <div class="btn-search">搜索</div>
                                </div> -->
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>标题名称</div>
                                <el-form-item prop="inputs.name">
                                    <el-input v-model="form.inputs.name" placeholder="请输入标题名称…" style="width: 430px"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>适用工况</div>
                                <el-form-item prop="inputs.apply_working">
                                    <el-checkbox-group v-model="form.inputs.apply_working">
                                        <el-checkbox label="房建工程" name="check"></el-checkbox>
                                        <el-checkbox label="市政工程" name="check"></el-checkbox>
                                        <el-checkbox label="公路工程" name="check"></el-checkbox>
                                        <el-checkbox label="水利工程" name="check"></el-checkbox>
                                        <el-checkbox label="园林工程" name="check"></el-checkbox>
                                        <el-checkbox label="桥隧工程" name="check"></el-checkbox>
                                        <el-checkbox label="其他" name="check"></el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>租赁费<span class="btn-add-cost" @click="empty">添加费用+</span></div>
                                <div class="cost-tag" ref="fzl">
                                    <span ref="zl" class="cost-tag-item" v-for="(item, index) in form.inputs.device_rental_fee_json" :key="index">
                                        {{ item.rental_fee + '/' }}{{ item.rental_type == 0 ? '日' : item.rental_type == 1 ? '月' : item.rental_type == 2 ? '半年' : '年' }}
                                        <i class="el-icon-close" @click="deleteI(index)"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>地址</div>
                                <el-form-item prop="inputs.address">
                                    <el-input id="test" v-model="form.inputs.address" placeholder="请输入地址…" style="width: 430px"></el-input>
                                    <el-button type="warning" class="dinwei" @click="$refs.map.setPlace(form.inputs.address)">定位</el-button>
                                    <div id="searchResultPanel" style="border: 1px solid #c0c0c0; width: 500px; height: auto; display: none"></div>
                                </el-form-item>
                                <Map class="map" style="height: 300px" ref="map" @getAddress="getAddress" :searchAddress="form.inputs.address" @getProvince="getProvince"> </Map>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>设备数量</div>
                                <el-form-item prop="inputs.num">
                                    <el-input type="number" v-model="form.inputs.num" placeholder="请输入…" style="width: 120px" :min="1" :max="9999" @blur="BlurText($event)"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>展示图片</div>
                                <el-form-item prop="inputs.show_img">
                                    <el-upload ref="zsimg" action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess1" :limit="1" :on-exceed="limitCheck" :on-remove="remove1">
                                        <!-- :on-change="handleChange1"
                  :class="objClass" -->
                                        <i class="el-icon-plus"></i>
                                        <div>添加上传图片</div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>列表图片</div>
                                <el-form-item prop="inputs.other_img">
                                    <el-upload ref="lbimg" action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess2" :limit="9" :on-exceed="limitCheck" :on-remove="remove2">
                                        <i class="el-icon-plus"></i>
                                        <div>添加上传图片</div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </div>
                    </section>
                    <section class="form">
                        <div class="form-title">参数信息</div>
                        <div class="form-main">
                            <div class="f-flex">
                                <div class="form-item">
                                    <div class="form-label"><span class="icons">*</span>设备品牌</div>
                                    <el-form-item prop="inputs.param_info.brand">
                                        <el-input v-model="form.inputs.param_info.brand" placeholder="请输入品牌名称…" style="width: 350px"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="form-item" style="margin-left: 20px">
                                    <div class="form-label"><span class="icons">*</span>设备类型</div>
                                    <el-form-item prop="inputs.param_info.type">
                                        <el-input v-model="form.inputs.param_info.type" placeholder="请输入设备类型…" style="width: 350px"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>备件</div>
                                <el-form-item prop="inputs.param_info.sparePart">
                                    <el-input v-model="form.inputs.param_info.sparePart" placeholder="请输入备件…" style="width: 350px"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </section>
                    <section class="form">
                        <div class="form-title">参数信息</div>
                        <div class="form-main">
                            <div class="form-item">
                                <div class="form-label">详细信息</div>
                                <!-- <div style="border: 1px solid #d3d3d3; height: 290px; border-radius: 4px; text-align: center; line-height: 290px">富文本框</div> -->
                                <my-editor ref="editor" @gethtml="gethtml"></my-editor>
                            </div>
                            <div class="form-item">
                                <div class="form-label">详情图片</div>
                                <el-form-item>
                                    <el-upload ref="xqimg" action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess3" :on-remove="remove3">
                                        <i class="el-icon-plus"></i>
                                        <div>添加上传图片</div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>资质证明(资质证明或者承诺书需要上传一个)</div>
                                <el-form-item>
                                    <el-upload ref="zzimg" action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess4" :limit="1" :on-exceed="limitCheck" :on-remove="remove4">
                                        <i class="el-icon-plus"></i>
                                        <div>添加上传图片</div>
                                    </el-upload>
                                </el-form-item>
                            </div>

                            <div class="form-item">
                                <div class="form-label"><span class="icons">*</span>承诺书(下载模板填写完成后盖章扫描成图片)<a target="_blank" class="dow" href="/承诺书.docx">模板下载</a></div>
                                <el-upload ref="letter_commitment" :on-success="up_success_letter_commitment" :on-remove="up_remove_letter_commitment" :accept="accept" action="http://114.67.170.224:21880/api/upload" list-type="picture-card">
                                    <i class="el-icon-plus"></i>
                                    <div>添加上传图片</div>
                                </el-upload>
                            </div>

                            <div class="form-item">
                                <div class="form-label">人员特殊驾驶证</div>
                                <el-form-item>
                                    <el-upload ref="ryimg" action="http://114.67.170.224:21880/api/upload" list-type="picture-card" :on-success="handleAvatarSuccess5" :limit="1" :on-exceed="limitCheck" :on-remove="remove5">
                                        <i class="el-icon-plus"></i>
                                        <div>添加上传图片</div>
                                    </el-upload>
                                </el-form-item>
                            </div>
                        </div>
                    </section>
                    <section class="form-btn-group">
                        <el-button class="btn-item btn-border" :plain="true" @click="cancel('form')">取消</el-button>
                        <el-button class="btn-item" :plain="true" @click="apply">申请发布</el-button>
                    </section>
                </div>
            </div>
            <Footer />
            <right-nav />
            <!-- 添加费用s -->
            <el-dialog title="添加费用" :show-close="false" :visible.sync="addCostDialogVisible" width="500px" class="add-cost-dialog">
                <div class="add-cost-form">
                    <!-- prop="inputs.device_rental_fee_json.rental_fee" -->
                    <el-form-item>
                        <el-input :min="0" :max="99999999" v-model="a1.rental_fee" placeholder="请输入金额" style="width: 170px" type="number" @keyup.native="proving($event)"></el-input>
                        <el-select class="select" v-model="a1.rental_type" placeholder="请选择" style="width: 130px; margin-left: 34px">
                            <!-- 130px 34px -->
                            <el-option label="日" value="0"></el-option>
                            <el-option label="月" value="1"></el-option>
                            <el-option label="半年" value="2"></el-option>
                            <el-option label="年" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <section class="form-btn-group">
                        <span class="btn-item btn-border" @click="clean">取消</span>
                        <span class="btn-item" @click="addTag">确定</span>
                    </section>
                </div>
            </el-dialog>
            <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
                <span class="back">提交成功,请选择返回页面</span>
                <span slot="footer" class="dialog-footer">
                    <el-button type="success" class="backhome" @click="gohome">返回首页</el-button>
                    <el-button type="primary" class="backDevice" @click="goMyDevice">我的设备</el-button>
                </span>
            </el-dialog>
        </el-form>

        <!-- 添加费用e -->
        <!-- 申请发布页面跳转 -->
    </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import RightNav from '@/components/rightNav'
import { publishDevice, typeList } from '@/api/publish.js'
import kits from '@/kits/index.js'
import myEditor from '../publishNeed/myEditor.vue'
import Map from '@/components/map.vue'
import { getuser } from '@/api/user'

export default {
    name: 'publishDevice',
    components: {
        Header,
        Footer,
        RightNav,
        myEditor,
        Map
    },
    data() {
        return {
            keyWord: '',
            searchList: [],
            typelist:[],
            /* tag标签 */
            a1: {
                rental_fee: '',
                rental_type: null
            },
            dialogVisible: false,
            addCostDialogVisible: false,
            checkList: [],
            input: '',
            form: {
                inputs: {
                    consumer_id: '',
                    name: '',
                    type_id: null,
                    apply_working: [],
                    address: '',
                    num: null,
                    show_img: '',
                    other_img: [],
                    // device_config_id: 1,
                    param_info: {
                        brand: '',
                        type: '',
                        sparePart: ''
                    },
                    detail_info: '',
                    detail_img: [],
                    proof_assets_img: '',
                    letter_commitment: '',
                    special_driver_img: '',
                    longitude: '',
                    latitude: '',
                    device_rental_fee_json: [],
                    province: '',
                    city: '',
                }
            },
            rules: {
                inputs: {
                    type_id: [{ required: true, message: '请选择分类', trigger: 'change' }],
                    name: [{ required: true, message: '请输入标题名称', trigger: 'blur' }],
                    apply_working: [{ type: 'array', required: true, message: '请至少选择一种适用工况', trigger: 'change' }],
                    address: [{ required: true, message: '请输入地址', trigger: 'change' }],
                    num: [
                        { required: true, message: '请输入数量', trigger: 'blur' },
                        { min: 1, max: 4, message: '1~9999', trigger: 'blur' }
                    ],
                    show_img: [{ required: true, message: '请上传展示图片，最多上传1张图片', trigger: 'change' }],
                    other_img: [{ required: true, message: '请上列表示图片，最多上传9张图片', trigger: 'change' }],
                    param_info: {
                        brand: [{ required: true, message: '请输入设备品牌', trigger: 'blur' }],
                        type: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
                        sparePart: [{ required: true, message: '请输入备件', trigger: 'blur' }]
                    }
                }
            }
        }
    },
    computed: {
        searchResult() {
            if (this.keyWord) {
                return this.searchList.filter(value => {
                    return value.type_name.includes(this.keyWord)
                })
            } else {
                return ''
            }
        }
    },
    created() {
        typeList()
            .then(res => {
                if (res.status != 200) return
                this.searchList = res.data
                console.log(this.searchList)
                this.checkList = kits.arr.tree_set(res.data, 'parent_id', 'type_id')
                console.log(this.checkList)
            })
            .catch(err => {
                return err
            })
    },
    mounted() {
        let _user = getuser()
        this.form.inputs.consumer_id = _user.id
        this.searchResult1();
    },
    methods: {
        // 输入为正数
        proving(e) {
            let keynum = e.which
            if (keynum == 189 || keynum == 109) {
                this.$message.warning('禁止输入负数')
                e.target.value = ''
            }
        },
        // 输入只能为正整数
        BlurText(e) {
            let boolean = new RegExp('^[1-9][0-9]*$').test(e.target.value)
            if (!boolean) {
                this.$message.warning('请输入正整数')
                e.target.value = ''
            }
        },
        // 取消
        cancel(form) {
            //重置表单数据
            console.log(this.$refs.lbimg)
            this.$refs[form].resetFields()
            // 清除图片
            this.$refs.zsimg.clearFiles()
            this.$refs.lbimg.clearFiles()
            this.$refs.xqimg.clearFiles()
            this.$refs.zzimg.clearFiles()
            this.$refs.letter_commitment.clearFiles()
            this.$refs.ryimg.clearFiles()
            // 清除富文本内容
            this.$refs.editor.html = ''
            // 清除图片数据
            this.form.inputs.show_img = ''
            this.form.inputs.other_img.splice(0, this.form.inputs.other_img.length)
            this.form.inputs.detail_img.splice(0, this.form.inputs.detail_img.length)
            this.form.inputs.proof_assets_img = ''
            this.form.inputs.special_driver_img = ''
            this.form.inputs.letter_commitment = ''
            // 清除金额
            this.form.inputs.device_rental_fee_json = []
            this.$router.push('/home')
        },
        // 申请发布
        apply() {
            console.log(this.$refs.fzl.children.length)
            // 获取经纬度
            this.form.inputs.longitude = this.$refs.map.coordinates.lng
            this.form.inputs.latitude = this.$refs.map.coordinates.lat
            if (this.form.inputs.proof_assets_img.length == 0 & this.form.inputs.letter_commitment.length == 0) {
                this.$message.error("资产证明图片或者承诺书图片未上传!");
                return false;
            }

            if (this.$refs.fzl.children.length > 0) {
                this.$refs.form.validate(value => {
                    // console.log(value)
                    if (value) {
                        this.dialogVisible = true
                        this.$message({
                            message: '申请发布成功',
                            type: 'success'
                        })
                        for (let i = 0; i <= this.form.inputs.apply_working.length - 1; i++) {
                            if (this.form.inputs.apply_working[i] == '房建工程') {
                                this.form.inputs.apply_working[i] = 1
                            } else if (this.form.inputs.apply_working[i] == '市政工程') {
                                this.form.inputs.apply_working[i] = 2
                            } else if (this.form.inputs.apply_working[i] == '公路工程') {
                                this.form.inputs.apply_working[i] = 3
                            } else if (this.form.inputs.apply_working[i] == '水利工程') {
                                this.form.inputs.apply_working[i] = 4
                            } else if (this.form.inputs.apply_working[i] == '园林工程') {
                                this.form.inputs.apply_working[i] = 5
                            } else if (this.form.inputs.apply_working[i] == '桥隧工程') {
                                this.form.inputs.apply_working[i] = 6
                            } else if (this.form.inputs.apply_working[i] == '其他') {
                                this.form.inputs.apply_working[i] = 7
                            }
                        }
                        let permission = this.form.inputs.apply_working.join(',')
                        this.form.inputs.apply_working = permission
                        publishDevice(this.form)
                    } else {
                        this.$message({
                            message: '请检查是否填写带*的必填选项',
                            type: 'warning'
                        })
                    }
                })
            } else {
                this.$message({
                    message: '租赁费不能为空',
                    type: 'warning'
                })
            }
        },
        // 超出文件总数时，触发
        limitCheck() {
            this.$message.warning('超出上传图片数量')
        },
        // 地图地址
        getAddress(address) {
            this.form.inputs.address = address
        },
        getProvince(province, city) {
            console.log(province, city);
            this.form.inputs.province = province
            this.form.inputs.city = city
        },
        // 返回首页
        gohome() {
            this.dialogVisible = false
            this.$router.push('/home')
        },
        // 返回我的设备
        goMyDevice() {
            this.dialogVisible = false
            this.$router.push('/personalCenter/myDevice')
            // this.$router.push({path:'/personalCenter/myDevice',query: {id: this.form.inputs.consumer_id }})
        },
        // 展示图片
        handleAvatarSuccess1(res) {
            // this.form.inputs.show_img = kits.img.url(res.data.value)
            this.form.inputs.show_img = res.data.value

            // console.log("展示图片",this.form.inputs.show_img)
        },
        remove1() {
            this.form.inputs.show_img = ''
            // //删除图片后显示上传框
            // this.objClass.upLoadShow = true;
            // 	this.objClass.upLoadHide = false;
        },
        // handleChange1() {
        // 		this.objClass.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
        // 		this.objClass.upLoadShow = false;
        // 	},
        // 列表图片
        handleAvatarSuccess2(res) {
            // this.form.inputs.other_img.push(kits.img.url(res.data.value))
            this.form.inputs.other_img.push(res.data.value)
        },
        remove2(file) {
            // let removefile = 'http://114.67.170.224:21880/api/view?url=' + file.response.data.value
            let removefile = file.response.data.value
            let removearr = []
            removearr.push(removefile)
            for (let i = 0; i < removearr.length; i++) {
                for (let j = 0; j < this.form.inputs.other_img.length; j++) {
                    if (removearr[i] == this.form.inputs.other_img[j]) {
                        this.form.inputs.other_img.splice(j, 1)
                    }
                }
            }
        },
        handleAvatarSuccess3(res) {
            // this.form.inputs.detail_img.push(kits.img.url(res.data.value))
            this.form.inputs.detail_img.push(res.data.value)
            console.log(this.form.inputs.detail_img)
        },
        remove3(file) {
            let removefile = file.response.data.value
            let removearr = []
            removearr.push(removefile)
            for (let i = 0; i < removearr.length; i++) {
                for (let j = 0; j < this.form.inputs.detail_img.length; j++) {
                    if (removearr[i] == this.form.inputs.detail_img[j]) {
                        this.form.inputs.detail_img.splice(j, 1)
                    }
                }
            }
        },
        // 资质证明
        handleAvatarSuccess4(res) {
            // this.form.inputs.proof_assets_img = kits.img.url(res.data.value)
            this.form.inputs.proof_assets_img = res.data.value
            // console.log(this.form.inputs.proof_assets_img)
        },
        remove4() {
            this.form.inputs.proof_assets_img = ''
        },

        // 承诺书
        up_success_letter_commitment(res) {
            this.form.inputs.letter_commitment = res.data.value
        },
        up_remove_letter_commitment() {
            this.form.inputs.letter_commitment = ''
        },
        // 人员特殊驾驶证
        handleAvatarSuccess5(res) {
            // this.form.inputs.special_driver_img = kits.img.url(res.data.value)
            this.form.inputs.special_driver_img = res.data.value
            // console.log(this.form.inputs.special_driver_img)
        },
        remove5() {
            this.form.inputs.special_driver_img = ''
        },
        // 确定添加金额
        addTag() {
            if (this.a1.rental_fee != '' && this.a1.rental_type != '') {
                this.form.inputs.device_rental_fee_json.push(this.a1)
                this.form.inputs.device_rental_fee_json = JSON.parse(JSON.stringify(this.form.inputs.device_rental_fee_json))
                this.addCostDialogVisible = false
            } else {
                this.$message({
                    message: '金额和类型不能为空',
                    type: 'warning'
                })
            }
        },
        // 取消添加金额
        clean() {
            this.a1.rental_type = ''
            this.a1.rental_fee = ''
            this.addCostDialogVisible = false
        },
        // 清空添加金额数据
        empty() {
            this.addCostDialogVisible = true

            //添加日期类型进入typelist
            this.form.inputs.device_rental_fee_json


            this.$nextTick(() => {
                (this.a1.rental_fee = ''), (this.a1.rental_type = '')
            })
        },
        //删除金额
        deleteI(index) {
            this.form.inputs.device_rental_fee_json.splice(index, 1)
        },
        // 选择器变化事件
        handleChange() {
            let nodesInfo = this.$refs.cascader.getCheckedNodes()
            this.form.inputs.type_id = nodesInfo[0].data.type_id
            // console.log(nodesInfo[0].data.type_id);
        },
        // 搜索选择分类
        picktype(id) {
            (this.form.inputs.type_id = id), (this.keyWord = '')
        },
        // 回去富文本数据
        gethtml(html) {
            this.form.inputs.detail_info = html
        },
        searchResult1() {
            var ac = new window.BMapGL.Autocomplete({ input: "test" });
            console.log("ac", ac);
            let that = this
            ac.addEventListener("onhighlight", function (e) {
                //鼠标放在下拉列表上的事件
                var str = "";
                var _value = e.fromitem.value;
                var value = "";
                if (e.fromitem.index > -1) {
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str =
                    "FromItem<br />index = " +
                    e.fromitem.index +
                    "<br />value = " +
                    value;
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str +=
                    "<br />ToItem<br />index = " +
                    e.toitem.index +
                    "<br />value = " +
                    value;
                document.getElementById("searchResultPanel").innerHTML = str;
            });

            var myValue;
            ac.addEventListener("onconfirm", function (e) {
                //鼠标点击下拉列表后的事件
                document.getElementById("searchResultPanel").innerHTML = ''
                var _value = e.item.value;
                myValue =
                    _value.province +
                    _value.city +
                    _value.district +
                    _value.street +
                    _value.business;
                document.getElementById("searchResultPanel").innerHTML =
                    "onconfirm<br />index = " +
                    e.item.index +
                    "<br />myValue = " +
                    myValue;
                that.$refs.map.setPlace(myValue)
            });
        },
    }
}
</script>

<style >
/deep/ .el-dialog {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
    border-radius: 10px;
}

.backhome {
    margin-right: 150px;
}

.backDevice {
    margin-right: 30px;
}

.back {
    font-size: 26px;
}

.search ul {
    width: 270px;
    min-height: 0;
    max-height: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.search ul li {
    width: 270px;
    height: 30px;
    background-color: #f8f8ff;
    line-height: 30px;
    border-bottom: 1px solid #cfcfcf;
    padding-left: 15px;
}

.icon {
    width: 10px;
}

.map {
    height: 300px;
}

.icons {
    color: red;
}
.dow{
    color: blue;
    font-size: 16px;
}
/* .dinwei {
  margin-left: 10px;
} */
.el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
}

.el-cascader-panel .el-radio__input {
    visibility: hidden;
}

.el-cascader-panel .el-cascader-node__postfix {
    top: 10px;
}
</style>
